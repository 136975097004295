import React, { useEffect, useMemo, useState } from 'react'
import MaterialReactTable from 'material-react-table'
import LambdaFetch from '../functions/FetchFromLambda'
import { debounce } from 'throttle-debounce'
import { Box, IconButton, CircularProgress } from '@mui/material'
import LoadingCircle from '../components/common/LoadingCircle'
import { ExportToCsv } from 'export-to-csv' //or use your library of choice here
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import CustomFooter from '../utils/CustomDataTableFooter'
import moment from 'moment'
import AthenaParameterTransformation from '../utils/AthenaParameterTransformation'

export const DashboardAthenaTableServerSideNoSelect = props => {
  //data and fetching state
  const [data, setData] = useState([])
  const [initalRender, setInitalRender] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRefetching, setIsRefetching] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [rowCount, setRowCount] = useState(0)
  const [executionId, setExecutionId] = useState(null)
  //table state
  const [columnFilters, setColumnFilters] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100
  })
  const [executionTempTable, setExecutionTempTable] = useState('temp_stevennogaliscom_1730268216624')

  useEffect(() => {
    if (initalRender) return
    debounceFetchTableData()
  }, [sorting]);

  useEffect(() => {
    if (initalRender) return
    setPagination({ ...pagination, pageIndex: 0 })
    debounceFetchTableData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getFilters = (isDownload = false) => {
    try {
      let invalidDate = false
      const filters = columnFilters.reduce((acc, cur, index) => {
        if (isDownload) {
          for (const sf in props.separatedDateFilters) {
            if (cur['id'] === sf) {
              const beginDateName = props.separatedDateFilters[sf][0]
              const endDateName = props.separatedDateFilters[sf][1]
              acc[beginDateName] = cur.value[0]
                ? cur.value[0].format(moment.HTML5_FMT.DATE)
                : ''
              acc[endDateName] = cur.value[1]
                ? cur.value[1].format(moment.HTML5_FMT.DATE)
                : ''
            }
          }
          // if(!Array.isArray(cur.value)) {
          // acc[cur['id']] = cur.value ? cur.value : ''
          // }
          acc[cur['id']] = cur.value ? cur.value : ''

        } else {
          if (Array.isArray(cur.value)) {
            const [beginDate, endDate] = [cur.value[0] ? cur.value[0].format(moment.HTML5_FMT.DATE) : '', cur.value[1] ? cur.value[1].format(moment.HTML5_FMT.DATE) : '']
            if (beginDate === 'Invalid Date' || endDate === 'Invalid Date') invalidDate = true
            if (beginDate && beginDate < '1900-01-01') invalidDate = true
            if (endDate && endDate < '1900-01-01') invalidDate = true

            acc[cur['id']] = [beginDate,endDate]
          } else {
            acc[cur['id']] = cur.value ? cur.value : ''
          }
        }
        return acc
      }, {})

      const hasInvalidDate = Object.values(filters).includes('Invalid Date')

      if (hasInvalidDate || invalidDate) {
        return false
      } else {
        return filters
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getSortObj = () => {
    if (!sorting) return {}
    const sortObj = sorting[0]
    if (!sortObj) return {}
    return {
      column: sortObj?.id,
      order: sortObj['desc'] ? 'desc' : 'asc'
    }
  }
  const fetchData = async () => {
    const filters = getFilters()
    if (!filters) return null
    if (!data.length) {
      setIsLoading(true)
    } else {
      setIsRefetching(true)
    }
    try {


      const tempTableName = `temp_${props.fetchInitialData.credentials.user.username.toLocaleLowerCase().replace(/[^a-zA-Z0-9]/g, '')}_${Date.now()}`

      setExecutionTempTable(tempTableName)

      const params = AthenaParameterTransformation(
        props.reportParamName ? props.reportParamName : props.reportName,
        props.params,
        filters
      )

      const fetchBody = {
        dashboard_name: props.reportName,
        temp_table_name: tempTableName,
        ...params,
        roles: 'app_user,all-access,default'
      }

      const resp = await LambdaFetch(
        'dashboard-trigger-temp-table',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify(fetchBody),
        '',
        props.fetchInitialData.credentials
      )

      if (resp.data.response.errorMessage) {
        window.alert(resp.data.response.errorMessage)
        setIsError(true)
        props.callback(null, resp.data.response.errorMessage)
        return
      }

      const tempTableData = await fetchFromTempTable({ temp_table_name: tempTableName })

      // Need to get row count in inital run
      setData(tempTableData.data)
      setRowCount(tempTableData.rowCount)
      setInitalRender(false)


      if (props.callback) {
        props.callback()
      }
    } catch (error) {
      setIsError(true)
      console.error(error)
      props.callback(null, error)
      return
    }
    setIsError(false)
    setIsLoading(false)
    setIsRefetching(false)
  }



  const fetchFromTempTable = async (params) => {
    const { temp_table_name, page = 1, pageSize = 100, filters, sortObj } = params

    const fetchBody = {
      temp_table_name,
      page,
      pageSize,
      filters,
      sortObj
    }

    const resp = await LambdaFetch(
      'dashboard-serverside-filter-temp-table',
      'post',
      props.fetchInitialData.credentials.user.accessToken,
      JSON.stringify(fetchBody),
      '',
      props.fetchInitialData.credentials
    )

    const transformedData = resp?.data?.tableData.map(obj => {
      const newObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[key.toUpperCase()] = obj[key];
        }
      }
      return newObj;
    });


    let tableData = props.transform ? props.transform(transformedData) : transformedData


    let rowCount = resp?.data?.totalRows

    return { data: tableData, rowCount }
  }


  const updateInnerSearch = (obj, cols) => {
    Object.keys(obj).forEach(cur => {
      const columnMetaData = cols.find(col => col.accessorKey.toUpperCase() === cur.toLocaleUpperCase())
      if (!!columnMetaData.innerSearch) {
        obj[cur] = `%${obj[cur]}`
      }
    })
    return obj
  }

  const updateTrimSearch = (obj, cols) => {
    Object.keys(obj).forEach(cur => {
      const columnMetaData = cols.find(col => col.accessorKey.toUpperCase() === cur.toLocaleUpperCase())
      if (!!columnMetaData.trimSearch) {
        const property = cur
        const value = obj[cur]
        delete obj[cur];
        obj[`trim(${property})`] = value
      }
    })
    return obj
  }

  const fetchUpdatedData = async (page = 1, pageSize = 100) => {

    setIsLoading(true)
    try {
      const filters = getFilters()
      if (!filters) {
        setIsLoading(false)
        return null
      }
      const filtersPlusWildCards = updateInnerSearch(filters, props.tableColumns)
      const filtersPlusTrims = updateTrimSearch(filtersPlusWildCards, props.tableColumns)


      const tempTableData = await fetchFromTempTable({
        temp_table_name: executionTempTable,
        filters: filtersPlusTrims,
        page: page,
        pageSize: pageSize,
        sortObj: getSortObj()
      })

      setData(tempTableData.data)
      setRowCount(tempTableData.rowCount)
      setInitalRender(false)
      setIsLoading(false)

      if (props.callback) {
        props.callback()
      }
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const debounceFetchTableData = debounce(1000, false, () => {
    fetchUpdatedData()
  })

  const DownloadFromTempTable = async () => {

    try {
      setIsDownloading(true)
      const filters = getFilters()
      const filtersPlusWildCards = updateInnerSearch(filters, props.tableColumns)
      const filtersPlusTrims = updateTrimSearch(filtersPlusWildCards, props.tableColumns)
      const params = AthenaParameterTransformation(
        props.reportParamName ? props.reportParamName : props.reportName,
        props.params,
        filters
      )
      if (!filters) return null

      const resp = await LambdaFetch(
        'dashboard-download-temp',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          dashboard_name: props.downloadFileName,
          temp_table_name: executionTempTable,
          filters: filtersPlusTrims,
          sortObj: getSortObj(),
          reportingParams: JSON.stringify({ ...params, filters: filtersPlusTrims }),
          event_name: `${props.reportName}_download`,
          report_name: props.reportName,
          roles: 'app_user,all-access,default'
        }),
        '',
        props.fetchInitialData.credentials
      )

      if (resp?.data?.response?.errorMessage) {
        window.alert(resp.data.response.errorMessage)
        setIsError(true)
        props.callback(null, resp.data.response.errorMessage)
        return
      }
      if (resp.data.statusCode === 200) {
        props.fetchInitialData.createSnack(
          'File sent to print manager',
          'success',
          3000
        );
      }
      setIsDownloading(false)

    } catch (error) {
      setIsDownloading(false)
      setIsError(true)
      console.error(error)
      props.callback(null, error)
      return
    }
  }

  const tableCols = props.tableColumns.map(r => {
    const upperAccessorKey = r.accessorKey.toUpperCase()
    return { ...r, size: 1, accessorKey: upperAccessorKey }
  })


  return (
    <MaterialReactTable
      columns={tableCols}
      data={data}
      initialState={{
        ...props.initialState,
        showColumnFilters: true,
        density: 'compact'
      }}
      manualFiltering
      manualPagination
      manualSorting
      enableFilterMatchHighlighting={false}
      muiToolbarAlertBannerProps={
        isError
          ? {
            color: 'error',
            children: 'Error loading data'
          }
          : undefined
      }
      enableGlobalFilter={false}
      enableStickyHeader
      enableFullScreenToggle={false}
      showSkeletons={true}
      muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
      onColumnFiltersChange={a => {
        setColumnFilters(a)
      }}
      onGlobalFilterChange={setGlobalFilter}
      maxMultiSortColCount={1}
      onSortingChange={setSorting}
      muiTableBodyRowProps={({ row, table }) => {
        if (row.index % 2 === 0) {
          return { style: { background: '#F7F7F7' } }
        }
      }}
      count={rowCount}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting
      }}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >{
            isDownloading ? (
              <CircularProgress size="30px" />
            ) : (
              <IconButton onClick={DownloadFromTempTable} icon={<CloudDownloadIcon />}>
                <CloudDownloadIcon />
              </IconButton>
            )
          }
        </Box>
      )}
      renderBottomToolbar={({ table }) => {
        return (
          <CustomFooter
            {...props}
            count={rowCount}
            page={pagination.pageIndex}
            rowsPerPage={pagination.pageSize}
            changeRowsPerPage={num => {
              setPagination({ ...pagination, pageSize: num })
              fetchUpdatedData(pagination.page, num)
            }}
            changePage={num => {
              setPagination({ ...pagination, pageIndex: num })
              fetchUpdatedData(num, pagination.pageSize)
            }}
            tableData={data}
            noPagination={false}
            textLabels={{ displayRows: 'of' }}
          />
        )
      }}
    />
  )
}

export default DashboardAthenaTableServerSideNoSelect
