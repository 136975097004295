import React, { useEffect } from 'react'
import TableMui from './TableMui'
import { dateFormat, currencyFormat } from '../utils/formatter'
import ReactTable from '../components/ReactTable'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
  Slide,
  Grid,
  TableFooter,
  TableCell,
  TableRow
} from '@material-ui/core'
import { AntTabs, AntTab } from './common/Tabs'
import fetchFromAthena from '../functions/FetchFromAthena'
import {
  glHeadersAcCols,
  glHeadersAmCols,
  glHeadersApCols,
  glHeadersArCols,
  glHeadersBlCols,
  glHeadersIcCols,
  glHeadersLmCols,
  glHeadersPoCols,
  glHeadersPrCols,
  glRelatedTransactionCols
} from '../constants/GlCols'
import { DashboardAthenaTableServerSide } from '../components/DashboardAthenaTableServerSide'
import { DashboardAthenaTableServerSideNoSelect } from '../components/DashboardAthenaTableServerSideNoSelect'
import LoadingCircle from '../components/common/LoadingCircle'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

export default function GlSystemHeaders (props) {
  const [headerData, setHeaderData] = React.useState({})

  //   const [headerData, setHeaderData] = React.useState({})
  const [realtedTransactionData, setRealtedTransactionData] =
    React.useState(null)
  const [systemData, setSystemData] = React.useState(null)
  const [value, setValue] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(true)
  const { headers } = props
  const { credentials } = props.fetchInitialData

  useEffect(() => {
    const fetchData = async () => {
      const queries = [
        'gl_header_read',
        `gl_header_system_${headers.SYSTEM ? headers.SYSTEM.toLowerCase() : ''}`
      ]

      const data = await Promise.allSettled(
        queries.map(query =>
          fetchFromAthena({
            queryName: query,
            headers,
            accessToken: credentials.user.accessToken,
            credentials: credentials
          })
        )
      ).then(results => {
        return results
      })

      const [header, system] = [data[0].value[0], data[1].value]
      setHeaderData(header ? header : null)
      setSystemData(system ? system : [])
      setIsLoading(false)
    }
    fetchData()
  }, [])

  const setSystemCols = type => {
    switch (type) {
      case 'AC':
        return glHeadersAcCols
      case 'AM':
        return glHeadersAmCols
      case 'AP':
        return glHeadersApCols
      case 'AR':
        return glHeadersArCols
      case 'BL':
        return glHeadersBlCols
      case 'IC':
        return glHeadersIcCols
      case 'LM':
        return glHeadersLmCols
      case 'PO':
        return glHeadersPoCols
      case 'PR':
        return glHeadersPrCols
    }
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const tabs = [
    {
      display: 'Gl Header',
      value: 0
    },
    {
      display: headers.SYSTEM,
      value: 1
    },
    {
      display: 'Related Transactions',
      value: 2
    }
  ]

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      maxWidth='xl'
      scroll='body'
      keepMounted
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogContent style={{minWidth: "90vw"}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AntTabs
              color={props.fetchInitialData.credentials.primaryAppColor}
              value={value}
              onChange={handleChange}
              aria-label='ant example'
            >
              {tabs.map(cur => {
                return (
                  <AntTab
                    label={cur.display}
                    value={cur.value}
                    color={props.fetchInitialData.credentials.primaryAppColor}
                  />
                )
              })}
            </AntTabs>
            <div style={{ marginTop: '1rem' }}>
              {value === 0 && (
                <div>
                  {isLoading ? (
                    <LoadingCircle height='50vh' />
                  ) : (
                    <MainGLHeaders headerData={headerData} />
                  )}
                </div>
              )}

              {value === 1 && (
                <div>
                  <ReactTable
                    cols={setSystemCols(headers.SYSTEM)}
                    data={systemData}
                  />
                </div>
              )}
              {value === 2 && (
                <div>
              

<DashboardAthenaTableServerSideNoSelect
              {...props}
              params={{ 
                COMPANY: headers.COMPANY,
                FISCAL_YEAR: headers.FISCAL_YEAR,
                ACCT_PERIOD: headers.ACCT_PERIOD,
                CONTROL_GROUP: headers.CONTROL_GROUP,
                SYSTEM: headers.SYSTEM,
                JE_TYPE: headers.JE_TYPE,
                JE_SEQUENCE: headers.JE_SEQUENCE
               }}
              tableColumns={glRelatedTransactionCols}
              reportParamName="gl_header_related_transactions"
              reportName='gl_header_related_transactions_temp'
              tableTitle={''}
              callback={()=>{}}
              downloadFileName={'GL_Related_Transactions_Download'}
            />

                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          onClick={props.handleClose}
          className='primaryVLButton'
          variant='contained'
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function MainGLHeaders (props) {
  const classes = useStyles()
  const { headerData } = props
  const printValue = (data, lookup, description, secondary, secondType) => {
    const isDate = description.toLowerCase().indexOf('date') !== -1
    return (
      <div>
        <span className={classes.descript}>{`${description}:  `}</span>
        <span className={classes.descriptVal}>
          {`${isDate ? dateFormat(data[lookup]) : data[lookup]}${
            secondary && data[secondary]
              ? ` (${
                  secondType !== 'date'
                    ? data[secondary]
                    : dateFormat(data[secondary])
                })`
              : ''
          } `}
        </span>
      </div>
    )
  }

  const twoRow = (col1, col2) => {
    return (
      <>
        <Grid item xs={6}>
          <div className={classes.descriptVal}>{col1}</div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.descriptVal}>{col2}</div>
        </Grid>
      </>
    )
  }

  const amountRow = (col1, col2, col3) => {
    return (
      <>
        <Grid item xs={4}>
          <div className={classes.descript}>{col1}</div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.descriptVal}>{col2}</div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.descriptVal}>{col3}</div>
        </Grid>
      </>
    )
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={1}
        direction='row'
        justify='flex-start'
        alignItems='stretch'
      >
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper}>
            <Grid
              container
              spacing={0}
              direction='row'
              justify='space-evenly'
              alignItems='flex-start'
            >
              <Grid xs={12}>
                {printValue(headerData, 'COMPANY', 'Company')}
                <div>
                  <span
                    className={classes.descript}
                  >{`Year,   Period:  `}</span>
                  <span className={classes.descriptVal}>
                    {' '}
                    {`${headerData.FISCAL_YEAR},   ${headerData.ACCT_PERIOD}`}
                  </span>
                </div>
                {printValue(headerData, 'SYSTEM', 'System', 'SYSTEM_DESC')}
                {printValue(headerData, 'TYPE', 'Type')}
                {printValue(headerData, 'JRNL_NBR', 'Journal Number')}
              </Grid>
              {twoRow(
                printValue(headerData, 'STATUS', 'Status'),
                printValue(headerData, 'HOLD_CODE', 'Hold Code')
              )}

              <Grid xs={12}>
                {printValue(headerData, 'TRANSACTION_DATE', 'Transaction Date')}

                {printValue(
                  headerData,
                  'HOLD_REM_OPER',
                  'Hold Removal Operator'
                )}
              </Grid>

              {twoRow(
                printValue(headerData, 'POSTING_DATE', 'Posting Date'),
                printValue(headerData, 'LAST_UPDT_DATE', 'Last Update Date')
              )}

              {twoRow(
                printValue(headerData, 'OPERATOR', 'Operator - Entry'),
                printValue(headerData, 'REL_OPERATOR', 'Release')
              )}

              <Grid xs={12}>
                {printValue(headerData, 'REFERENCE', 'Reference')}
                {printValue(headerData, 'SOURCE_CODE', 'Source Code')}
              </Grid>

              {twoRow(
                printValue(headerData, 'DOCUMENT_NBR', 'Document'),
                printValue(headerData, 'JRNL_BOOK_NBR', 'Journal Book')
              )}

              <Grid xs={12}>
                {printValue(headerData, 'AUTO_REV', 'Auto Reverse')}
                {printValue(headerData, 'BASE_ZONE', 'Base Zone')}
              </Grid>

              {twoRow(
                printValue(headerData, 'CURRENCY_CODE', 'Currency'),
                printValue(headerData, 'BASERATE', 'Base Rate')
              )}

              <Grid xs={12}>
                {printValue(headerData, 'NBR_LINES', 'Number of Transactions')}
                {printValue(headerData, 'CLOSE_SEQ', 'Close Sequence Number')}
              </Grid>
              {amountRow('', 'Debits', 'Credits')}
              {amountRow(
                'Entered Amounts:',
                currencyFormat(headerData.ENTRY_DB),
                currencyFormat(headerData.ENTRY_CR)
              )}
              {amountRow(
                'Base Amounts:',
                currencyFormat(headerData.BASE_DEBITS),
                currencyFormat(headerData.BASE_CREDITS)
              )}
              {amountRow(
                'Control Amounts:',
                currencyFormat(headerData.CNTRL_AMOUNT),
                ''
              )}
              <Grid item xs={12}>
                <div style={{ marginTop: '0.5rem' }}></div>
              </Grid>

              {amountRow(
                'Entered Units:',
                headerData.BASE_AUTO_DB,
                headerData.BASE_AUTO_CR
              )}
              {amountRow(
                'Control Units:',
                headerData.UNIT_CNTRL_DB,
                headerData.UNIT_CNTRL_CR
              )}
              {amountRow(
                'Reversing Units:',
                headerData.AUTO_UNIT_DB,
                headerData.AUTO_UNIT_CR
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
