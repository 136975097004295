import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  MenuItem,
  TextField,
  Paper,
  InputAdornment,
  Button
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import { poInquiryReactTableCols } from '../constants/PoCols'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import DialogTable from '../components/DialogTable'
import LoadingCircle from '../components/common/LoadingCircle'
import { smallScreenWidth } from '../constants/AppConstants'
import { ServerSideTable } from '../components/ServerSideTable'
import ReactTableServerSide from '../components/ReactTableServerSide'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import AutocompleteDropDown from '../components/AutocompleteDropdown'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ReactTable from '../components/ReactTable'
import { DashboardAthenaTableServerSide } from '../components/DashboardAthenaTableServerSide'
import { DashboardAthenaTableServerSideNoSelect } from '../components/DashboardAthenaTableServerSideNoSelect'

const _ = require('lodash')

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  treeRoot: {
    flexGrow: 1,
    margin: '1rem'
  }
}))

const startAdornment = (
  <>
    <InputAdornment position='start'>
      <div></div>
    </InputAdornment>
  </>
)
const defaultHeaders = {
  company: '',
  fromDate: '',
  toDate: '',
  vendorGroup: '',
  vendor: '',
  vendorLoc: '',
  buyer: '',
  status: '',
  poCode: '',
  poNumber: '',
  totalAmt: '',
  totalLines: ''
}

export default function PoInquiry (props) {
  const [state, setstate] = useState({
    isLoading: false,
    data: null,
    fetched: true,
    tableKey: JSON.stringify(defaultHeaders)
  })
  const [showTable, setShowTable] = useState(false)
  const [headers, setHeaders] = useState(defaultHeaders)
  const [clearHeader, setClearHeaders] = React.useState(false)

  const classes = useStyles()

  const { credentials } = props.fetchInitialData


  const handleCallback = (values, error) => {
    if (error) {
      setstate({ ...state, isLoading: false });
      setShowTable(false);
      return null;
    }
    setstate({ ...state, isLoading: false });
  };

  const tableDataTransformation = (rows) => {
    return rows.map(row => {
          return {
            ...row,
            PO_LINK:
              row.PO_NUMBER && row.COMPANY ? (
                <div
                  className='editLink'
                  onClick={() => {
                    const win = window.open(
                      `/po?company=${row.COMPANY}&po=${row.PO_NUMBER}&pocode=${row.PO_CODE}`,
                      '_blank'
                    )
                    win.focus()
                  }}
                >
                  {row.PO_NUMBER}
                </div>
              ) : (
                ''
              )
          }
        })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (JSON.stringify(headers) === state.tableKey) return null;
    setShowTable(true);
    setstate({ ...state, isLoading: true, tableKey: JSON.stringify(headers) });
  };

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Purchase Order Inquiry
      </Typography>
      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={3}
            >
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'company'}
                  name={'Company'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Company'}
                  value={headers.company}
                  category={'company'}
                  screen={'po'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      company: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              {[
                {
                  id: 'fromDate',
                  label: 'From Date'
                },
                {
                  id: 'toDate',
                  label: 'To Date'
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <TextField
                      id={field.id}
                      key={clearHeader}
                      style={{ marginTop: '5px', width: '160px' }}
                      type={'date'}
                      required={true}
                      label={field.label}
                      variant='standard'
                      value={headers.id}
                      fullWidth
                      onChange={e => {
                        setHeaders({
                          ...headers,
                          [field.id]: !!e.target.value ? e.target.value : null
                        })
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <span />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )
              })}

              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'vendorGroup'}
                  name={'Vendor Group'}
                  width='125px'
                  key={clearHeader}
                  required={false}
                  variant='standard'
                  label={'Vendor Group'}
                  value={headers.vendorGroup}
                  category={'vendor-group'}
                  screen={'po-vendor'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      vendorGroup: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <ServerSideAutoCompletev2
                  disabled={!headers.vendorGroup}
                  required={false}
                  id={'vendor'}
                  width='125px'
                  label={'Vendor'}
                  key={clearHeader}
                  dontAutoHighlight
                  dontOpenOnFocus
                  accessToken={credentials.user.accessToken}
                  error={`No Vendor Found`}
                  variant='standard'
                  handleChange={(id, hit) => {
                    setHeaders({ ...headers, vendor: id })
                  }}
                  credentials={credentials}
                  apiResource='po-inquiry'
                  secondaryParams={{
                    limit: 30,
                    lookupField: 'vendor',
                    action: 'autocomplete-vendor',
                    ...headers
                  }}
                />
              </Grid>

              <Grid item>
                <ServerSideAutoCompletev2
                  disabled={!headers.vendor}
                  required={false}
                  id={'vendorLoc'}
                  width='125px'
                  label={'Location'}
                  key={clearHeader}
                  dontAutoHighlight
                  dontOpenOnFocus
                  accessToken={credentials.user.accessToken}
                  error={`No Location Found`}
                  variant='standard'
                  handleChange={(id, hit) => {
                    setHeaders({ ...headers, vendorLoc: id })
                  }}
                  credentials={credentials}
                  apiResource='po-inquiry'
                  secondaryParams={{
                    limit: 30,
                    lookupField: 'vendorLoc',
                    action: 'autocomplete-vendor-location',
                    ...headers
                  }}
                />
              </Grid>
              <Grid item>
                <ServerSideAutoCompletev2
                  required={false}
                  id={'buyer'}
                  width='125px'
                  label={'Buyer'}
                  key={clearHeader}
                  dontAutoHighlight
                  dontOpenOnFocus
                  accessToken={credentials.user.accessToken}
                  error={`No Buyer Found`}
                  variant='standard'
                  handleChange={(id, hit) => {
                    setHeaders({ ...headers, buyer: id })
                  }}
                  credentials={credentials}
                  apiResource='po-inquiry'
                  secondaryParams={{
                    limit: 30,
                    lookupField: 'buyer',
                    action: 'autocomplete-buyer',
                    ...headers
                  }}
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'status'}
                  name={'Status'}
                  width='125px'
                  key={clearHeader}
                  required={false}
                  variant='standard'
                  label={'Status'}
                  value={headers.status}
                  category={'status'}
                  screen={'po-inquiry'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      status: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item style={{ marginTop: '5px' }}>
                <TextField
                  id='poCode'
                  label='PO Code'
                  style={{ width: '125px' }}
                  value={headers.poCode}
                  onChange={e =>
                    setHeaders({ ...headers, poCode: e.target.value })
                  }
                  InputProps={{
                    startAdornment: (
                      <>
                        <InputAdornment position='start'>
                          <div></div>
                        </InputAdornment>
                      </>
                    )
                  }}
                />
              </Grid>
              <Grid item>
                <ServerSideAutoCompletev2
                  disabled={!headers.company}
                  required={false}
                  id='poNumber'
                  width='175px'
                  label='PO Number'
                  accessToken={credentials.user.accessToken}
                  error='No PO found'
                  variant='standard'
                  handleChange={(id, hit) => {
                    setHeaders({ ...headers, poNumber: id })
                  }}
                  credentials={credentials}
                  apiResource='po'
                  secondaryParams={{
                    action: 'autocomplete-po',
                    limit: 10,
                    company: headers.company
                  }}
                  dontOpenOnFocus
                />
              </Grid>

              <Grid item style={{ marginTop: '5px' }}>
                <TextField
                  id='totalAmt'
                  label='Total Amount'
                  style={{ width: '125px' }}
                  value={headers.totalAmt}
                  onChange={e =>
                    setHeaders({ ...headers, totalAmt: e.target.value })
                  }
                  InputProps={{
                    startAdornment: (
                      <>
                        <InputAdornment position='start'>
                          <div></div>
                        </InputAdornment>
                      </>
                    )
                  }}
                />
              </Grid>
              <Grid item style={{ marginTop: '5px' }}>
                <TextField
                  id='totalLines'
                  label='Total Lines'
                  style={{ width: '125px' }}
                  value={headers.totalLines}
                  onChange={e =>
                    setHeaders({ ...headers, totalLines: e.target.value })
                  }
                  InputProps={{
                    startAdornment: (
                      <>
                        <InputAdornment position='start'>
                          <div></div>
                        </InputAdornment>
                      </>
                    )
                  }}
                />
              </Grid>
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
              {!!(
                headers.company ||
                headers.fromDate ||
                headers.toDate ||
                headers.vendorGroup ||
                headers.vendor ||
                headers.vendorLoc ||
                headers.buyer ||
                headers.status ||
                headers.poCode ||
                headers.poNumber ||
                headers.totalAmt ||
                headers.totalLines
              ) && (
                <Button
                  onClick={() => {
                    setClearHeaders(!clearHeader)
                    setHeaders(defaultHeaders)
                  }}
                  style={{ marginLeft: '0.5rem' }}
                >
                  {' '}
                  Clear
                </Button>
              )}
            </div>
          </form>
        </Paper>

        {showTable && (
          <div style={{ marginTop: '1rem ' }}>
            {/* <DashboardAthenaTableServerSide
              {...props}
              key={state.tableKey}
              params={{ ...headers }}
              tableColumns={poInquiryReactTableCols}
              reportName='po_inquiry_serverside'
              tableTitle={''}
              transform={tableDataTransformation}
              callback={handleCallback}
              noPagination
            /> */}

<DashboardAthenaTableServerSideNoSelect
          {...props}
          key={state.tableKey}
          params={{ ...headers }}
          tableColumns={poInquiryReactTableCols}
          reportParamName="po_inquiry_serverside"
          reportName='po_inquiry_serverside_temp'
          tableTitle={''}
          callback={handleCallback}
          transform={tableDataTransformation}
          downloadFileName={'Po_Inquiry_Download'}
        />

          </div>
        )}

        {/* {!!showTable && (
          <div style={{ marginTop: '1rem ' }}>
            <ReactTableServerSide
              {...props}
              key={state.tableKey}
              params={{ ...headers }}
              action="get-po-inquiry-serverside"
              apiResource={'po-inquiry'}
              transform={tableDataTransformation}
              tableTitle={''}
              tableColumns={poInquiryReactTableCols}
              callback={handleCallback}
              noPagination
              downloadAction={'po-inquiry-serverside-download'}
              downloadFileName={'Po_Inquiry_Download'}
            />
          </div>
        )} */}

        {/* {state.data && (
          <Grid
            item
            xs={12}
            style={{ position: 'relative', marginTop: '1rem' }}
          >
            <ReactTable cols={poInquiryReactTableCols} data={state.data} />
          </Grid>
        )} */}
      </div>
    </div>
  )
}
