import { currencyFormat, dateFormat, noWrapFormat } from '../utils/formatter';

export const attachmentCols = [
    {
        accessorKey: 'doc_type',
        header: 'Type',
        enableSorting: false,
        enableColumnFilter: true
      },
    {
        accessorKey: 'file_name',
        header: 'File Name',
        enableSorting: false,
        enableColumnFilter: false
      },
      {
        accessorKey: 'file_type',
        header: 'File Type',
        enableSorting: false,
        enableColumnFilter: false,
        size: 1,
      },
      {
        accessorKey: 'date_uploaded',
        header: 'Date Uploaded',
        enableSorting: false,
        enableColumnFilter: false,
        size: 1,
        Cell: ({ cell }) => dateFormat(cell.getValue())
      },
      {
        accessorKey: 'link',
        header: 'Download',
        enableSorting: false,
        enableColumnFilter: false,
      }
    ]