import React, { createContext, useState } from 'react';

// Create the context
const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
  const [contextData, setContextData] = useState(null); // This is your global state

  return (
    <AppContext.Provider value={{ contextData, setContextData }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;