import { dateFormat, currencyFormat } from '../utils/formatter';
import useDateRangeCol from './useDateRangeCol';

const useActransReactTableCols = () => {
  const tranDateCol = useDateRangeCol('TRAN_DATE', 'Tran Date');

  const actransReactTableCols = [
    {
      accessorKey: 'FISCAL_YEAR',
      header: 'Year',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'PERIOD',
      header: 'Prd',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'COMPANY',
      header: 'Co',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'ACTIVITY',
      header: 'Activity',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },

    {
      accessorKey: 'ACTIVITY_GRP',
      header: 'Activity Group',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },

    {
      accessorKey: 'ACCT_CATEGORY',
      header: 'Account Cat',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },
    tranDateCol,
    // {
    //   accessorKey: 'TRAN_DATE',
    //   header: 'Tran Date',
    //   size: 1,
    //   enableSorting: false,
    //   enableColumnFilter: false,
    //   Cell: ({ cell }) => dateFormat(cell.getValue()),
    // },
    {
      accessorKey: 'POSTING_DATE',
      header: 'Posting Date',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ cell }) => dateFormat(cell.getValue()),
    },
    {
      accessorKey: 'ACCT_UNIT',
      header: 'Acct Unit',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'ACCOUNT',
      header: 'Account',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'SUB_ACCOUNT',
      header: 'Sub Acct',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'DESCRIPTION',
      header: 'Desc',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'TRAN_AMOUNT',
      header: 'Amt',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'FROM_COMPANY',
      header: 'From Comp',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'UNITS_AMOUNT',
      header: 'Unit Amt',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'TRAN_CURRENCY',
      header: 'Currency',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'SOURCE_CODE',
      header: 'Source Code',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'OPERATOR',
      header: 'Operation',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'CONTROL_GROUP',
      header: 'Control Group',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'TRANS_TYPE',
      header: 'Type',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'STATUS',
      header: 'Status',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'SYSTEM',
      header: 'System',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
  ];
  return actransReactTableCols;
};


const useActransbyActivityReactTableCols = () => {
  const tranDateCol = useDateRangeCol('TRAN_DATE', 'Tran Date');

  const actransReactTableCols = [
    {
      accessorKey: 'FISCAL_YEAR',
      header: 'Year',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'PERIOD',
      header: 'Prd',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'COMPANY',
      header: 'Co',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'ACTIVITY',
      header: 'Activity',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },

    {
      accessorKey: 'ACTIVITY_GRP',
      header: 'Activity Group',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },

    {
      accessorKey: 'ACCT_CATEGORY',
      header: 'Account Cat',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },
    // {
    //   accessorKey: 'TRAN_DATE',
    //   header: 'Tran Date',
    //   size: 1,
    //   enableSorting: false,
    //   enableColumnFilter: false,
    //   Cell: ({ cell }) => dateFormat(cell.getValue()),
    // },
    tranDateCol,
    {
      accessorKey: 'POSTING_DATE',
      header: 'Posting Date',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ cell }) => dateFormat(cell.getValue()),
    },
    {
      accessorKey: 'ACCT_UNIT',
      header: 'Acct Unit',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'ACCOUNT',
      header: 'Account',
      size: 1,
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'SUB_ACCOUNT',
      header: 'Sub Acct',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'DESCRIPTION',
      header: 'Desc',
      size: 1,
      innerSearch: true,
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      accessorKey: 'TRAN_AMOUNT',
      header: 'Amt',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ cell }) => currencyFormat(cell.getValue()),
    },
    {
      accessorKey: 'FROM_COMPANY',
      header: 'From Comp',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'UNITS_AMOUNT',
      header: 'Unit Amt',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'TRAN_CURRENCY',
      header: 'Currency',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'SOURCE_CODE',
      header: 'Source Code',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'OPERATOR',
      header: 'Operation',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'CONTROL_GROUP',
      header: 'Control Group',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'TRANS_TYPE',
      header: 'Type',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'STATUS',
      header: 'Status',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'SYSTEM',
      header: 'System',
      size: 1,
      enableSorting: false,
      enableColumnFilter: false,
    },
  ];
  return actransReactTableCols;
};

export { useActransReactTableCols,useActransbyActivityReactTableCols };
