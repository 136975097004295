import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  MenuItem,
  TextField,
  Paper,
  InputAdornment,
  Button
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import {
  glTransCols,
  glTransServerSideCols2,
  glActivityCols,
  moreGlTansCols,
  attribCols
} from '../constants/GlCols'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import DialogTable from '../components/DialogTable'
import LoadingCircle from '../components/common/LoadingCircle'
import { smallScreenWidth } from '../constants/AppConstants'
import { ServerSideTable } from '../components/ServerSideTable'
import ReactTableServerSide from '../components/ReactTableServerSide'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import AutocompleteDropDown from '../components/AutocompleteDropdown'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { DashboardAthenaTableServerSide } from '../components/DashboardAthenaTableServerSide'
import { DashboardAthenaTableServerSideNoSelect } from '../components/DashboardAthenaTableServerSideNoSelect'

import GlSystemHeaders from '../components/GlSystemHeaders'
import { SettingsBackupRestore } from '@material-ui/icons'
const _ = require('lodash')

const serversideEntriesPerPage = 50

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  treeRoot: {
    flexGrow: 1,
    margin: '1rem'
  }
}))

const startAdornment = (
  <>
    <InputAdornment position='start'>
      <div></div>
    </InputAdornment>
  </>
)
const defaultHeaders = {
  fiscalYear: '',
  fromPeriod: '',
  toPeriod: '',
  company: '',
  system: '',
  accountUnit: '',
  account: '',
  subAccount: '',
  subAccount: '',
  includeIntercompany: 1,
  fromDate: '',
  toDate: ''
}

export default function GlTransServerSideV2(props) {
  const [state, setstate] = useState({
    glOptions: null,
    options: null,
    isLoading: false,
    data: null,
    fetched: true,
    isServerSide: false,
    tableKey: JSON.stringify(defaultHeaders)
  })
  const [showTable, setShowTable] = useState(false)
  const [structureData, setStructureData] = useState(null)
  const [headers, setHeaders] = useState(defaultHeaders)
  const [objInfo, setObjInfo] = React.useState(null)
  const [moreInfo, setMoreInfo] = React.useState(null)
  const [attribInfo, setAttribInfo] = React.useState(null)
  const [clearHeader, setClearHeaders] = React.useState(false)
  const [glHeader, setGlHeader] = React.useState(null)
  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  const handleClose = () => {
    setObjInfo(null)
    setMoreInfo(null)
    setAttribInfo(null)
  }
  const handleOpenActivity = row => {
    setObjInfo([row])
  }
  const viewAttributes = async objId => {
    const resp = await LambdaFetch(
      'gl-trans',
      'post',
      credentials.user.accessToken,
      JSON.stringify({
        action: 'get-attrib',
        objId
      }),
      '',
      credentials
    )

    setAttribInfo(resp.data.attribData)
  }
  const handleSubmit = e => {
    e.preventDefault()
    if (JSON.stringify(headers) === state.tableKey) return null
    setShowTable(true)
    setstate({ ...state, isLoading: true, tableKey: JSON.stringify(headers) })
  }
  const formatData = data => {
    return data.map(row => {
      if (row.ACTIVITY) {
        row['ACTIVITY_LINK'] = (
          <div
            className='editLink'
            onClick={() => {
              const win = window.open(
                `/actrans?id=${row.OBJ_ID}&type=GLT_OBJ_ID`,
                '_blank'
              )
              win.focus()
            }}
          >
            {row.ACTIVITY}
          </div>
        )
      } else {
        row['ACTIVITY_LINK'] = ''
      }
      // let temp = row.ACCOUNT
      // row.ACCOUNT = `${temp}${row.SUB_ACCOUNT ? `-${row.SUB_ACCOUNT}` : ''}`
      row['MORE'] = (
        <div className='editLink' onClick={() => setMoreInfo([row])}>
          view
        </div>
      )
      if (row.HAS_GTMXVALUE) {
        row['GTMXVALUE'] = (
          <div
            className='editLink'
            onClick={() => viewAttributes(row.HAS_GTMXVALUE)}
          >
            view
          </div>
        )
      }
      return row
    })
  }
  const handleCallback = (values, error) => {
    if (error) {
      setstate({ ...state, isLoading: false })
      setShowTable(false)
      return null
    }
    setstate({ ...state, isLoading: false })
  }

  const tableDataTransformation = rows => {
    return rows.map(row => {
      if (row.ACTIVITY) {
        row['ACTIVITY_LINK'] = (
          <div
            className='editLink'
            onClick={() => {
              const win = window.open(
                `/actrans?id=${row.OBJ_ID}&type=GLT_OBJ_ID`,
                '_blank'
              )
              win.focus()
            }}
          >
            {row.ACTIVITY}
          </div>
        )
      } else {
        row['ACTIVITY_LINK'] = ''
      }
      // let temp = row.ACCOUNT
      // row.ACCOUNT = `${temp}${row.SUB_ACCOUNT ? `-${row.SUB_ACCOUNT}` : ''}`
      row['MORE'] = (
        <div className='editLink' onClick={() => setMoreInfo([row])}>
          view
        </div>
      )
      if (row.HAS_GTMXVALUE) {
        row['GTMXVALUE'] = (
          <div
            className='editLink'
            onClick={() => viewAttributes(row.HAS_GTMXVALUE)}
          >
            view
          </div>
        )
      }

      row['SYSTEM_LINK'] = (
        <div className='editLink' onClick={() => setGlHeader(row)}>
          {row.SYSTEM}
        </div>
      )
      return row
    })
  }

  if (!state.fetched) {
    return <LoadingCircle />
  }

  const options = getTableOptions({
    cols: glTransServerSideCols2,
    fileName: `GL_Trans`
  })
  options.downloadOptions.filterOptions.useDisplayedColumnsOnly = false

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      {glHeader && (
        <GlSystemHeaders
          {...props}
          headers={{
            ...glHeader,
            COMPANY: headers.company,
            FISCAL_YEAR: headers.fiscalYear
          }}
          handleClose={() => setGlHeader(null)}
        />
      )}
      <DialogTable
        isOpen={!!objInfo}
        handleClose={handleClose}
        tableData={objInfo}
        cols={glActivityCols}
        simpleTable={true}
      />
      <DialogTable
        isOpen={!!moreInfo}
        handleClose={handleClose}
        tableData={moreInfo}
        cols={moreGlTansCols}
        simpleTable={true}
      />

      <DialogTable
        isOpen={!!attribInfo}
        handleClose={handleClose}
        tableData={attribInfo}
        cols={attribCols}
        simpleTable={true}
      />
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        GL Trans
      </Typography>
      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={3}
            >
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'fiscalYear'}
                  name={'Year'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Year'}
                  value={headers.fiscalYear}
                  category={'fiscal-year'}
                  screen={'gl-trans'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      fiscalYear: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'company'}
                  name={'Company'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Company'}
                  value={headers.company}
                  category={'company'}
                  screen={'gl-trans'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      company: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'fromPeriod'}
                  name={'Period From'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Period From'}
                  value={headers.fromPeriod}
                  category={'period'}
                  screen={'gl-trans'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      fromPeriod: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'toPeriod'}
                  name={'Period To'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Period To'}
                  value={headers.toPeriod}
                  category={'period'}
                  screen={'gl-trans'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      toPeriod: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={headers.includeIntercompany}
                      onChange={e => {
                        setHeaders({
                          ...headers,
                          includeIntercompany: e.target.checked ? 1 : 0
                        })
                      }}
                      name='includeIntercompany'
                      color='primary'
                    />
                  }
                  label='Include Intercompany'
                />
              </Grid>
            </Grid>

            <Grid
              container
              alignContent='center'
              alignItems='flex-start'
              spacing={3}
            >
              {[
                {
                  id: 'system',
                  label: 'System',
                  dataField: 'SYSTEM',
                  dataType: 'string',
                  isRequired: false
                },
                {
                  id: 'accountUnit',
                  dataField: 'ACCT_UNIT',
                  label: 'Account Unit',
                  dateType: 'number',
                  isRequired: false
                },
                {
                  id: 'account',
                  dataField: 'ACCOUNT',
                  label: 'Account',
                  dateType: 'number',
                  isRequired: false
                },
                {
                  id: 'subAccount',
                  dataField: 'SUB_ACCOUNT',
                  label: 'Sub Account',
                  dateType: 'number',
                  isRequired: false
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <ServerSideAutoCompletev2
                      disabled={
                        !(
                          headers.fiscalYear &&
                          headers.fromPeriod &&
                          headers.toPeriod &&
                          headers.company
                        )
                      }
                      required={field.isRequired}
                      id={field.id}
                      width='125px'
                      label={field.label}
                      key={clearHeader}
                      dontAutoHighlight
                      dontOpenOnFocus
                      accessToken={credentials.user.accessToken}
                      error={`No ${field.label} Found`}
                      variant='standard'
                      handleChange={(id, hit) => {
                        setHeaders({ ...headers, [field.id]: id })
                      }}
                      credentials={credentials}
                      apiResource='gl-trans'
                      secondaryParams={{
                        limit: 10,
                        lookupField: field.dataField,
                        action: 'autocomplete-gl-trans-v3',
                        ...headers
                      }}
                    />
                  </Grid>
                )
              })}

              {[
                {
                  id: 'fromDate',
                  label: 'From Date'
                },
                {
                  id: 'toDate',
                  label: 'To Date'
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <TextField
                      id={field.id}
                      key={clearHeader}
                      style={{ marginTop: '5px', width: '160px' }}
                      type={'date'}
                      required={false}
                      label={field.label}
                      variant='standard'
                      value={headers.id}
                      fullWidth
                      onChange={e => {
                        setHeaders({
                          ...headers,
                          [field.id]: !!e.target.value ? e.target.value : null
                        })
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <span />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                disabled={!(headers.fiscalYear && headers.acctPeriod)}
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
              {!!(
                headers.fiscalYear ||
                headers.fromPeriod ||
                headers.toPeriod ||
                headers.company ||
                headers.system ||
                headers.accountUnit ||
                headers.account ||
                headers.subAccount ||
                headers.fromDate ||
                headers.toDate
              ) && (
                  <Button
                    onClick={() => {
                      setClearHeaders(!clearHeader)
                      setHeaders(defaultHeaders)
                    }}
                    style={{ marginLeft: '0.5rem' }}
                  >
                    {' '}
                    Clear
                  </Button>
                )}
            </div>
          </form>
        </Paper>

        {showTable && (
          <div style={{ marginTop: '1rem ' }}>

            <DashboardAthenaTableServerSideNoSelect
              {...props}
              key={state.tableKey}
              params={{ ...headers }}
              tableColumns={glTransServerSideCols2}
              reportParamName="gl_trans_read_serverside_v3"
              reportName='gl_trans_read_serverside_v3_temp'
              tableTitle={''}
              transform={tableDataTransformation}
              callback={handleCallback}
              downloadFileName={'GL_Trans_Download'}
            />
          </div>
        )}
      </div>
    </div>
  )
}
