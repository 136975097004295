import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import AppAdfs from './AppAdfs';
import AppCognito from './AppCognito';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { AppProvider } from './AppContext';

ReactDOM.render(
  <AppProvider>
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <AppCognito />
      </SnackbarProvider>
    </BrowserRouter>
  </AppProvider>,
  document.getElementById('root')
);
