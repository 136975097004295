import React, { useState, useEffect } from 'react'
import {
    CircularProgress
} from '@material-ui/core'
import LambdaFetch from '../functions/FetchFromLambda'
import { attachmentCols } from '../constants/AttachmentCols'
import ReactTable from '../components/ReactTable';

export default function Attachments(props) {
    const [state, setstate] = useState({
        isLoading: true,
        attachments: [],
        downloadId: null
    })
    const { credentials } = props.fetchInitialData

    const { type, key1, key2, key3, key4, key5 } = props

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        try {
            const resp = await LambdaFetch(
                'attachments',
                'post',
                credentials.user.accessToken,
                JSON.stringify({
                    action: 'attachment-read',
                    type,
                    key1,
                    key2,
                    key3,
                    key4,
                    key5,
                }),
                '',
                credentials
            )
            console.log(resp)
            setstate({
                ...state,
                attachments: resp.data.attachments,
                isLoading: false
            })
        } catch (e) {
            console.log(e)
        }
    }

    const downloadFile = async (props) => {

        const { id, key, name, time, contentDisposition } = props
        setstate({ ...state, downloadId: id })
        const resp = await LambdaFetch(
            'attachments',
            'post',
            credentials.user.accessToken,
            JSON.stringify({
                action: 'attachment-download',
                key,
                name,
                time,
                contentDisposition
            }),
            '',
            credentials
        )
        console.log(resp)
        if (resp.success) {
            const downloadUrl = resp.data.url
            window.open(downloadUrl, '_blank')
        }
        setstate({ ...state, downloadId: null })
    }

    const attachTableData = state.attachments.map(row => {
        row.link = state.downloadId === row.id ? (
            <div style={{ paddingLeft: '1rem' }}>
                <CircularProgress
                    className='loading-circle'
                    disableShrink
                    size={16}
                />
            </div>
        )
            : (
                <div
                    className='editLink'
                    onClick={() => {
                        downloadFile({
                            id: row.id,
                            key: row.s3_link,
                            time: 3600,
                            name: row.file_name,
                            contentDisposition: row.file_type
                        })
                    }}
                >
                    download
                </div>
            )
        return row
    })

    return (
        <div style={{ padding: '1.5rem 0.5rem' }}>
            <ReactTable
                isLoading={state.isLoading}
                cols={attachmentCols}
                data={attachTableData}
            />
        </div>
    )
}
