import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Typography,
  Grid,
  TableFooter,
  TableRow,
  TableCell,
  Paper,
  Button
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LambdaFetch from '../functions/FetchFromLambda'
import LoadingButton from '../components/LoadingButton'
import { trialBalanceCols } from '../constants/GlCols'
import TableMui from '../components/TableMui'
import getTableOptions from '../constants/TableOptions'
import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import clsx from 'clsx'
import { currencyTextFormat } from '../utils/formatter'
import ReactTable from '../components/ReactTable'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import ReactTableServerSide from '../components/ReactTableServerSide'
import AutocompleteDropDown from '../components/AutocompleteDropdown'
import { DashboardAthenaTableServerSide } from '../components/DashboardAthenaTableServerSide'
import { DashboardAthenaTableServerSideNoSelect } from '../components/DashboardAthenaTableServerSideNoSelect'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  footerCell: {
    backgroundColor: '#F5F5F5',
    borderBottom: 'none',
    zIndex: 999
  },
  stickyFooterCell: {
    position: 'sticky',
    bottom: '-1px',
    zIndex: 100
  },
  rightTableHead: {
    '& > span': {
      justifyContent: 'flex-end',
      marginRight: '0'
    }
  }
}))

const defaultHeaders = {
  fiscalYear: '',
  company: '',
  acctUnit: '',
  account: '',
  subAccount: '',
  period: '',
  hideZeroRows: 0
}

export default function TrialBalanceServerSide (props) {
  const [state, setstate] = useState({
    options: null,
    isLoading: false,
    data: null,
    fetched: false
  })
  const [headers, setHeaders] = useState(defaultHeaders)
  const [showTable, setShowTable] = useState(false)
  const [clearHeader, setClearHeaders] = React.useState(false)

  const classes = useStyles()

  const { credentials } = props.fetchInitialData

  const handleSubmit = async e => {
    e.preventDefault()
    if (JSON.stringify(headers) === state.tableKey) return null
    setShowTable(true)
    setstate({ ...state, isLoading: true, tableKey: JSON.stringify(headers) })
  }

  const handleCallback = (values, error) => {
    if (error) {
      setstate({ ...state, isLoading: false })
      setShowTable(false)
      return null
    }
    setstate({ ...state, isLoading: false })
  }

  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        GL Trial Balance
      </Typography>
      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-end'
              spacing={3}
            >
                 <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'fiscalYear'}
                  name={'Year'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Year'}
                  value={headers.fiscalYear}
                  category={'fiscal-year'}
                  screen={'trial-balances'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      fiscalYear: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'period'}
                  name={'period'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Period'}
                  value={headers.period}
                  category={'period'}
                  screen={'trial-balances'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      period: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
             <Grid item>
                <AutocompleteDropDown
                  {...props}
                  id={'company'}
                  name={'company'}
                  width='125px'
                  key={clearHeader}
                  required={true}
                  variant='standard'
                  label={'Company'}
                  value={headers.company}
                  category={'company'}
                  screen={'trial-balances'}
                  onChange={val =>
                    setHeaders({
                      ...headers,
                      company: val ? val.key_value : ''
                    })
                  }
                />
              </Grid>
              {[
                {
                  id: 'acctUnit',
                  label: 'Account Unit',
                  dataField: 'ACCT_UNIT',
                  dataType: '',
                  isRequired: false
                },
                {
                  id: 'account',
                  label: 'Account',
                  dataField: 'ACCOUNT',
                  dataType: 'number',
                  isRequired: false
                },
                {
                  id: 'subAccount',
                  label: 'Sub Account',
                  dataField: 'SUB_ACCOUNT',
                  dataType: 'number',
                  isRequired: false
                }
              ].map(field => {
                if (field.id === 'period') {
                  return (
                    <Grid item>
                      <SimpleAutoComplete
                        id={'period'}
                        name={'Period'}
                        width='125px'
                        key={clearHeader}
                        required={true}
                        variant='standard'
                        label={'Period'}
                        value={headers.period}
                        onChange={val =>
                          setHeaders({ ...headers, period: val })
                        }
                        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
                        getOptionLabel={val => val.toString()}
                      />
                    </Grid>
                  )
                } else {
                  return (
                    <Grid key={field.id} item>
                      <ServerSideAutoCompletev2
                        disabled={false}
                        required={field.isRequired}
                        id={field.id}
                        width='125px'
                        label={field.label}
                        key={clearHeader}
                        dontAutoHighlight
                        dontOpenOnFocus
                        accessToken={credentials.user.accessToken}
                        error={`No ${field.label} Found`}
                        variant='standard'
                        handleChange={(id, hit) => {
                          setHeaders({ ...headers, [field.id]: id })
                        }}
                        credentials={credentials}
                        apiResource='trial-balance'
                        secondaryParams={{
                          limit: 15,
                          lookupField: field.dataField,
                          action: 'autocomplete-trial-balance',
                          ...headers
                        }}
                      />
                    </Grid>
                  )
                }
              })}

              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={headers.hideZeroRows}
                      onChange={e => {
                        setHeaders({
                          ...headers,
                          hideZeroRows: e.target.checked ? 1 : 0
                        })
                      }}
                      name='active'
                      color='primary'
                    />
                  }
                  label='Hide Zero Rows'
                />
              </Grid>
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                disabled={!(headers.fiscalYear && headers.acctPeriod)}
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
              {!!(headers.fiscalYear || headers.acctPeriod) && (
                <Button
                  onClick={() => {
                    setClearHeaders(!clearHeader)
                    setHeaders(defaultHeaders)
                  }}
                  style={{ marginLeft: '0.5rem' }}
                >
                  {' '}
                  Clear
                </Button>
              )}
            </div>
          </form>
        </Paper>

        {/* {!!showTable && (
          <div style={{ marginTop: '1rem ' }}>
            <ReactTableServerSide
              {...props}
              key={state.tableKey}
              params={{ ...headers }}
              action='get-trial-balances-serverside'
              apiResource={'trial-balance'}
              transform={r => r}
              tableTitle={''}
              tableColumns={trialBalanceCols}
              callback={handleCallback}
              noPagination
              noSort
              downloadAction={'get-trial-balances-serverside-download'}
              initialState={{
                columnVisibility: { CHART: false, FY: false, PRD: false }
              }}
            />
          </div>
        )} */}


{showTable && (
          <div style={{ marginTop: '1rem ' }}>


{/* <DashboardAthenaTableServerSideNoSelect
              {...props}
              key={state.tableKey}
              params={{ ...headers }}
              tableColumns={trialBalanceCols}
              reportName='gl_trial_balance_serverside_temp'
              tableTitle={''}
              callback={handleCallback}
              transform={tableDataTransformation}
              downloadFileName={'GL_Trans_Download'}
            /> */}



<DashboardAthenaTableServerSideNoSelect
          {...props}
          key={state.tableKey}
              params={{ ...headers }}
          tableColumns={trialBalanceCols}
          reportParamName="gl_trial_balance_serverside"
          reportName='gl_trial_balance_serverside_temp'
          tableTitle={''}
          callback={handleCallback}
          downloadFileName={'Trial_Balance_Download'}
        />


          </div>
        )}
      </div>
    </div>
  )
}
